import React, { useMemo } from 'react'
import styled from 'styled-components'
import { pressHotkeys } from '../helpers/utils'
import { NavLink } from 'react-router-dom'

const TopBlock = styled.div`
  display: flex;
  font-size: 14px !important;
  margin-left: 4%;
  height: 40px;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 767px) {
    margin-left: 5px;
    font-size: 13px !important;
  }
  @media (max-width: 435px) {
      font-size: 11px !important;
  }
`
const WrapperButton = styled.div`
  border: none;
  color: #767676;
  font-weight: 400 !important;
  background: transparent;
`

const ReturnButton = styled(NavLink)`
  color: #767676;
  margin: 10px;
  &:hover {
    text-decoration: underline;
  }
`

const Text = styled.p`
  font-weight: 500;
  color: '#313334';
  margin-left: 10px;
`

const BreadCrumbs = ({ historyPages }) => {
    const breadCrumbs = useMemo(() => {
    const currentPage = (
      <Text key={1}>{historyPages[historyPages.length - 1].title}</Text>
    )
    return [
      ...historyPages.slice(0, historyPages.length - 1).map((el) => (
        <WrapperButton key={el.link}>
          <ReturnButton
            tabIndex="0"
            aria-label={`Go Back to the ${el.title}`}
            onKeyDown={pressHotkeys}
            to={el.link}
          >
            {el.title}
          </ReturnButton>
          <span aria-hidden>{'>'}</span>
        </WrapperButton>
      )),
      currentPage,
    ]
  }, [historyPages])
  return <TopBlock>{breadCrumbs}</TopBlock>
}

export default BreadCrumbs
