import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from 'react'
import Parser from 'html-react-parser'
import styled, { css, keyframes } from 'styled-components'
import { useSwipeable } from 'react-swipeable'
import {
  fetchTutorialStepsApi,
} from '../../../services'
import NavButton from '../../../components/NavButton'
import InfoBox from '../../../components/InfoBox'
import { BadgesCtx } from '../../../BadgesCtx'
import { postAnalytics } from '../../../services/analytics'
import { pressHotkeys } from '../../../helpers/utils'
import LoaderMini from '../../../components/LoaderMini'
import { getParam } from '../../../helpers/parameters'
import { useConfigContext } from '../../../hooks'

const tooltipOptions = {
  textColor: '#221F20',
  border: true,
  borderColor: '#012979',
  backgroundColor: 'white',
  event: 'click',
  multiline: true,
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-top: 10px;
  align-items: center;
  @media (max-width: 720px) {
    padding-bottom: 137px;
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: row;
`

const Section = styled.div`
  height: 600px;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  margin: 30px auto;
  min-height: 0;
  overflow-x: hidden;

  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
    height: auto;
  }
`
const TopBlock = styled.div`
  width: 70%;
  color: #313334;
  margin: -15px 0 0 25%;
  text-align: left;

  h1 {
    font-size: 20px;
    padding-top: 50px;
    text-align: initial;
  }

  h2 {
    font-size: 16px;
    text-align: initial;
    margin: 0px 0px 9px 35px;
  }
  @media (max-width: 767px) {
    margin-left: 10px;
    margin-top: -70px;
  }
`

const BottomBlock = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  min-height: 300px;
  margin-left: 70px;
  @media (max-width: 767px) {
    margin: 0;
  }
  ::-webkit-scrollbar {
    width: 8px;
    border-radius: 4px;
    background: #e8e8e9;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #221f20;
  }
`

const OneStep = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  min-height: 100px;
  margin-left: 70px;
  @media (max-width: 767px) {
    margin: 0;
  }
`

const StepTextWrapper = styled.div`
  width: 456px;
  min-height: 60px;
  background: white;
  border-radius: 9px;
  text-align: initial;
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: row;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  margin: 15px 0px 20px 0px;
  padding: 10px 0 20px;

  div {
    color: ${(props) => (props.active ? '#221F20' : '#5D5D5D')};
    font-size: 14px;
    line-height: 20px;
    width: 65%;
    margin: 0 10px 0 15px;

    span {
      p {
        margin: 0 0 30px 0;
      }
    }
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`

const Number = styled.p`
  min-width: 84px;
  height: 44px;
  background: ${({ active, theme }) => (active ? theme.retailer.primaryColor : '#5D5D5D')};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
  margin: 0 10px;

  span {
    color: ${({ active, theme }) => (active && theme.retailer.primaryColor !== '#012979'
    ? '#221F20'
    : '#FFFFFF')};
    font-size: ${({ isMobile }) => (isMobile ? '15px' : '24px')};
  }
`

const PhoneContainer = styled.div`
  cursor: pointer;
  transform-origin: 50% 50%;
  position: relative;
  width: ${({ frameWidth }) => (frameWidth ? `${frameWidth}px` : 'auto')};
  @media (max-width: 767px) {
    margin: 0 auto;
  }
`
const ImageWrapper = styled.div`
  float: left;
  margin-bottom: 0px;
  transform: rotate(0deg) translate3d(0px, 0px, 0px);
  width: ${({ frameWidth }) => (frameWidth ? `${frameWidth}px` : 'auto')};
`
const PhoneFrame = styled.img`
  position: relative;
  z-index: 2;
  vertical-align: middle;
`
const ScreenPhoneImage = styled.img`
  position: relative;
  float: left;
  top: ${({ offsetTop }) => `${offsetTop}px`};
  left: ${({ offsetLeft }) => `${offsetLeft}px`};
  width: ${({ XSize }) => XSize}px;
  height: ${({ YSize }) => YSize}px;
  margin-bottom: ${({ offsetTop }) => `${offsetTop}px`};
  z-index: ${({ ZPosition }) => ZPosition + 1};
  display: ${({ offsetTop }) => !offsetTop && 'none'};
`
const TouchButtonContainer = styled.div`
  cursor: pointer;
  width: 32px;
  height: 32px;
  position: absolute;
  z-index: 9;
  display: block;
  left: ${({ ButtonXPos }) => ButtonXPos}px;
  top: ${({ ButtonYPos }) => ButtonYPos}px;
  transition: 0.3s all ease;
  opacity: ${({ ButtonXPos }) => (ButtonXPos ? '1' : '0')};
  animation: ${({ Swap }) => (Swap ? css`${Swap} infinite 1s linear` : '')};
  box-shadow: ${({ BoxShadow }) => (BoxShadow ? css`${BoxShadow}` : 'none')};
  border-radius: 100%;

  button {
    cursor: pointer;
    background-color: rgba(69, 133, 49, 0.8);
    border: 2px solid white;
    height: 32px;
    width: 32px;
    position: relative;
    top: 0;
    left: 0;
    outline-offset: 2px;
    border-radius: 100%;
    box-shadow: 5px 5px 10px 0 #0000006b;
  }
`

const OptionsWrapper = styled.div`
  width: 90%;
  margin: -15px 3px 6px 25px;

  @media (max-width: 767px) {
    margin: 0;
    width: 100%;
  }
`

const SwipeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const SwipeButtonWrapper = styled.button`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  font-size: 14px !important;
  position: absolute;
  border: none;
  background: none;
  ${({ theme, disabled, direction }) => css`
  ${direction}: 3%;
  div {
  ${disabled ? 'border-top: 3px solid gray; border-right: 3px solid gray;' : ''};
  }
  p {
    margin: 0;
    color: ${(() => {
    if (disabled) return 'gray'
    if (theme.retailer.default) return '#012979'
    return '#221f20'
  })()}
  }
  `}
`

const SliderDotWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 60%;
  margin: 0 auto 15px auto;
`

const SliderDot = styled.button`
  border: none;
  width: 12px;
  height: 12px;
  cursor: pointer;
  background: ${({ isCurrentStep, theme }) => (isCurrentStep ? theme.retailer.primaryColor : '#a6a6a6')};
  border-radius: 50%;
`
const Arrow = styled.div`
  margin-top: 3px;
  width: 8px;
  height: 8px;
  border-radius: 2px;

  ${({ theme, arrowRotate }) => css`
    border-top: 3px solid ${theme.retailer.primaryColor};
    border-right: 3px solid ${theme.retailer.primaryColor};
    transform: ${arrowRotate ? 'rotate(45deg)' : 'rotate(228deg)'};
    ${arrowRotate ? 'margin-right: 8px' : 'margin-left: 12px'}
  `}
`

const parseHTML = (text, options) => Parser(text, {
  replace: (domNode) => {
    if (domNode.attribs && domNode.attribs.class === 'explanation') {
      return (
        <InfoBox
          node={domNode}
          tooltipOptions={options}
          icon="categories/information.svg"
        />
      )
    }
    return null
  },
})

const extractContent = (html) => new DOMParser().parseFromString(html, 'text/html').documentElement.textContent

const handleTutorialContentClick = (e) => {
  if (e.target.href) {
    if (e.target.href.indexOf(window.location.host) === -1) {
      e.preventDefault()

      window.open(e.target.href, '_blank')
    }
  }
}

const DisplaySteps = (
  filteredSteps,
  number,
  goToStep,
  inputRef = null,
  isMobile,
) => {
  const options = filteredSteps.map((value, index) => (
    <StepTextWrapper
      role="button"
      id={`tutorial-step-${index + 1}`}
      className="tutorial-step"
      active={index + 1 === number}
      key={`tutorial-step-${index}`}
      onClick={(e) => {
        goToStep(index + 1)
        handleTutorialContentClick(e)
      }}
      ref={index + 1 === number ? inputRef : null}
      onKeyDown={pressHotkeys}
      aria-label={`Step ${index + 1} ${extractContent(
        value.tutorialstep.step,
      )}`}
      tabIndex='0'
    >
      <Number active={index + 1 === number} isMobile={isMobile}>
        <span aria-hidden>{index + 1}</span>
      </Number>
      <div
        aria-label={`${extractContent(
          value.tutorialstep.step,
        )}`}
      >
        <span aria-hidden>{parseHTML(value.tutorialstep.step, tooltipOptions)}</span>
      </div>
    </StepTextWrapper>
  ))

  return <OptionsWrapper>{options}</OptionsWrapper>
}

const DisplayStep = (filteredSteps, number, inputRef = null, isMobile) => {
  const option = filteredSteps.find((item) => number === item.order)
  return (
    <OptionsWrapper>
      <StepTextWrapper
        id={`tutorial-step-${number}`}
        className="tutorial-step"
        active={true}
        ref={inputRef}
        tabIndex="0"
      >
        <Number active={true} isMobile={isMobile}>
          <span
            tabIndex="0"
            aria-label={`Step ${number} of ${filteredSteps.length} ${
              filteredSteps[number - 1].tutorial.name
            }`}
          >
            Step {number} of {filteredSteps.length}
          </span>
        </Number>
        <div
          tabIndex="0"
          aria-label={`${extractContent(
            option.tutorialstep.step,
          )}`}
        >
          <span>{parseHTML(option.tutorialstep.step, tooltipOptions)}</span>
        </div>
      </StepTextWrapper>
    </OptionsWrapper>
  )
}

const TutorialSteps = ({
  id,
  completedStep,
  completeSteps,
  badgeId,
  closeSteps,
}) => {
  const { customerConfig: { customer } } = useConfigContext()
  const assetsUrl = getParam('assetsUrl')
  const { updateProgress } = useContext(BadgesCtx)
  const [stepNumber, setStepNumber] = useState(1)
  const [displayedStepNumber, setDisplayedStepNumber] = useState(1)
  const [screenWidth, setScreenWidthsetValue] = useState(window.screen.width)
  const [steps, setSteps] = useState([])
  const [title, setTitle] = useState()
  const [isFetching, setIsFetching] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [frameProperties, setFrameProperties] = useState({
    wrapperWidth: 0,
    homeScreenOffsetTop: 0,
  })
  const getScreenWidth = (e) => setScreenWidthsetValue(e.target.innerWidth)
  const tutorialStartTime = useRef(new Date())
  const inputRef = useRef(null)
  const phoneFrameRef = useRef()

  const isMobile = screenWidth < 767

  const currentStep = steps[stepNumber - 1]

  const imageUrl = currentStep?.tutorialstep.imageLocation

  const borderPhone = steps[0]?.phoneScreenImage

  const buttonXPos = currentStep?.action?.on_screen === true
    ? currentStep?.buttonScreenImages[0].buttonXUpperLeft
      + borderPhone?.screenXUpperLeft
    : currentStep?.buttonScreenImages[0].buttonXUpperLeft

  const buttonYPos = currentStep?.action?.on_screen === true
    ? currentStep?.buttonScreenImages[0].buttonYUpperLeft
      + borderPhone?.screenYUpperLeft
    : currentStep?.buttonScreenImages[0].buttonYUpperLeft

  const keyframesUp = keyframes`
    from { transform: translateX(0px) translateY(0px); opacity: 0.4 }
    to { transform: translateX(0px) translateY(-70px); opacity: 1 }
  `
  const keyframesDown = keyframes`
    from { transform: translateX(0px) translateY(0px) }
    to { transform: translateX(0px) translateY(70px) }
  `
  const keyframesRight = keyframes`
    from { transform: translateX(0px) translateY(0px) }
    to { transform: translateX(70px) translateY(0px) }
  `
  const keyframesLeft = keyframes`
    from { transform: translateX(0px) translateY(0px) }
    to { transform: translateX(-70px) translateY(0px) }
  `

  const trackProgress = useCallback(
    (type, stepNum, titleOverride) => postAnalytics({
      category: 'tutorials',
      data: {
        type,
        title: title || titleOverride,
        step: stepNum,
        tutorial_id: id,
        customer,
      },
    }),
    [id, title],
  )

  const getSteps = useCallback(async () => {
    const {
      steps: stepsArray,
      title: name,
      product: { faceplate },
    } = await fetchTutorialStepsApi(id)
    const ActionTypes = (type) => {
      switch (type) {
        case 'swipe_up':
          return 'UP'
        case 'swipe_down':
          return 'DOWN'
        case 'swipe_left':
          return 'LEFT'
        case 'swipe_right':
          return 'RIGHT'
        case 'automate':
          return 'NONE'
        case 'click':
          return 'NULL'
        default:
          return 'NONE'
      }
    }
    const filteredValue = stepsArray
      .map((item) => ({
        ...item,
        buttonScreenImages: [
          {
            buttonXSize: 32,
            buttonXUpperLeft: item?.action?.x,
            buttonYSize: 32,
            buttonYUpperLeft: item?.action?.y,
          },
        ],
        packageFile: {
          location: '',
        },
        phoneScreenImage: {
          faceplate_behind: Boolean(faceplate.is_behind),
          imageLocation: `${assetsUrl}${
            faceplate[
              item.orientation === 'portrait'
                ? 'portrait_image'
                : 'landscape_image'
            ]
          }`,
          screenXSize:
            faceplate[
              item.orientation === 'portrait'
                ? 'portrait_width'
                : 'landscape_width'
            ],
          screenXUpperLeft:
            faceplate[
              item.orientation === 'portrait' ? 'portrait_x' : 'landscape_x'
            ],
          screenYSize:
            faceplate[
              item.orientation === 'portrait'
                ? 'portrait_height'
                : 'landscape_height'
            ],
          screenYUpperLeft:
            faceplate[
              item.orientation === 'portrait' ? 'portrait_y' : 'landscape_y'
            ],
        },
        tutorial: {
          name,
        },
        tutorialstep: {
          animationJson: [
            {
              delay: String(item.delay),
              image: `${assetsUrl}${item.file_path}`,
            },
            {
              delay: String(item.delay),
              image: `${assetsUrl}${item.file_path}`,
            },
          ],
          imageLocation: `${assetsUrl}${item.file_path}`,
          lcdButton: Boolean(item.on_screen),
          orientation: item.orientation,
          outputOrder: item.order,
          step: item.text,
          swipeOrAction: ActionTypes(item?.action?.type),
        },
      }))
    setSteps(filteredValue)
    setTitle(name)
    trackProgress('start', displayedStepNumber, name)
    setIsFetching(false)
  }, [id, displayedStepNumber, trackProgress])

  const changeActiveStep = (i) => {
    const currentNumber = steps.findIndex((item) => (i === item.order))
    setStepNumber(currentNumber + 1)
    setDisplayedStepNumber(i)
    trackProgress('goto-step', i)
  }

  const handlers = useSwipeable({
    onSwipedRight: () => {
      if (displayedStepNumber !== 1) {
        changeActiveStep(displayedStepNumber - 1)
      }
    },
    onSwipedLeft: () => {
      if (stepNumber === steps.length && !isSubmitting) {
        navButtonClick()
      }
      if (displayedStepNumber < steps.length) {
        changeActiveStep(displayedStepNumber + 1)
      }
    },
  })

  const nextSubstep = () => {
    if (stepNumber === steps.length) navButtonClick()
    if (displayedStepNumber < steps.length) {
      if (steps[stepNumber].tutorialstep.step) {
        changeActiveStep(displayedStepNumber + 1)
        return
      }
      setStepNumber(stepNumber + 1)
    }
  }

  const prevSubstep = () => {
    if (stepNumber === 1) return
    const currentIndex = stepNumber - 1
    setStepNumber(currentIndex)
    const isOrderDifferent =
      steps[currentIndex]?.order !== steps[currentIndex - 1]?.order

    if (isOrderDifferent) {
      setDisplayedStepNumber(displayedStepNumber - 1)
      trackProgress('goto-step', displayedStepNumber - 1)
    }
  }

  const PrevStep = () => (
    <SwipeButtonWrapper
      direction="left"
      tabIndex='0'
      onKeyDown={pressHotkeys}
      onClick={prevSubstep}
    >
      <Arrow arrowRotate={false} />
      <p>Swipe Left</p>
    </SwipeButtonWrapper>
  )

  const NextStep = ({ text }) => (
    <SwipeButtonWrapper
      direction="right"
      disabled={isSubmitting}
      tabIndex='0'
      onKeyDown={pressHotkeys}
      onClick={nextSubstep}
    >
      <p>{text}</p>
      <Arrow arrowRotate={true} isSubmitting={isSubmitting} />
    </SwipeButtonWrapper>
  )

  useEffect(() => {
    if (isFetching) {
      getSteps()
    }
  }, [getSteps, isFetching])

  const hardcodedData = {
    1: { left: '', top: '' },
    2: { left: '112', top: '238' },
    3: { left: '', top: '' },
    4: { left: '318', top: '208' },
    5: { left: '', top: '' },
    6: { left: '325', top: '213' },
    7: { left: '', top: '' },
  }

  const navButtonClick = async () => {
    setIsSubmitting(true)
    if (!completedStep) {
      trackProgress('completed', displayedStepNumber)
      updateProgress(badgeId)

      completeSteps()
    } else {
      trackProgress('repeat-completed', displayedStepNumber)
      closeSteps()
    }
    postAnalytics({
      category: 'tutorialtime',
      data: {
        time_spent: new Date().valueOf() - tutorialStartTime.current.valueOf(),
        customer,
      },

    })
  }

  const getStepButtonText = () => {
    if (stepNumber === steps.length && completedStep) return 'Go Back'
    if (stepNumber === steps.length && !completedStep) {
      return 'Complete Tutorial'
    }
    if (isMobile) return 'Swipe Right'
    return 'Next Step'
  }

  let liIndex = 0
  const moveOnArrow = (event) => {
    const { key } = event

    if (event.target.localName === 'li' && (key === 'ArrowUp' || key === 'ArrowDown')) event.preventDefault()

    if (key === 'ArrowRight') {
      if (stepNumber === steps.length) navButtonClick()
      if (displayedStepNumber < steps.length) {
        changeActiveStep(displayedStepNumber + 1)
      }
    }
    if (key === 'ArrowLeft') {
      if (displayedStepNumber !== 1) {
        changeActiveStep(displayedStepNumber - 1)
      }
    }

    const liCollection = document.querySelectorAll('li')
    if (liCollection.length > 0) {
      if (key === 'ArrowUp' && liIndex > 0) {
        liIndex -= 1
        liCollection[liIndex].focus()
      }
      if (key === 'ArrowDown' && liIndex < liCollection.length - 1) {
        liIndex += 1
        liCollection[liIndex].focus()
      }
    }
  }

  const getAnimationInfo = (actionType) => {
    switch (actionType) {
      case 'swipe_up':
        return keyframesUp
      case 'swipe_down':
        return keyframesDown
      case 'swipe_left':
        return keyframesLeft
      case 'swipe_right':
        return keyframesRight
      default:
        return ''
    }
  }

  const getBoxShadowAnimation = (actionType) => {
    switch (actionType) {
      case 'swipe_up':
        return 'rgba(0, 0, 0, 0.8) 0px 10px 20px 0px, rgba(0, 0, 0, 0.8) 0px 30px 20px 0px, rgba(0, 0, 0, 0.8) 0px 50px 20px 0px, rgba(0, 0, 0, 0.8) 0px 70px 20px 0px'
      case 'swipe_down':
        return 'rgba(0, 0, 0, 0.8) 0px -10px 20px 0px, rgba(0, 0, 0, 0.8) 0px -30px 20px 0px, rgba(0, 0, 0, 0.8) 0px -50px 20px 0px, rgba(0, 0, 0, 0.8) 0px -70px 20px 0px'
      case 'swipe_left':
        return 'rgba(0, 0, 0, 0.8) 10px 0px 20px 0px, rgba(0, 0, 0, 0.8) 30px 0px 20px 0px, rgba(0, 0, 0, 0.8) 50px 0px 20px 0px, rgba(0, 0, 0, 0.8) 0px 0px 20px 0px'
      case 'swipe_right':
        return 'rgba(0, 0, 0, 0.8) -10px 0px 20px 0px, rgba(0, 0, 0, 0.8) -30px 0px 20px 0px, rgba(0, 0, 0, 0.8) -50px 0px 20px 0px, rgba(0, 0, 0, 0.8) -70px 0px 20px 0px'
      default:
        return 'none'
    }
  }

  useEffect(() => {
    window.addEventListener('resize', getScreenWidth)
    return () => {
      window.removeEventListener('resize', getScreenWidth)
    }
  }, [])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
    const listLi = document.querySelectorAll('li')
    if (listLi.length > 0) {
      listLi.forEach((item) => item.setAttribute('tabIndex', 0))
    }
    document.addEventListener('keydown', moveOnArrow)
    return () => {
      document.removeEventListener('keydown', moveOnArrow)
    }
  }, [displayedStepNumber])

  const filteredSteps = steps.filter(
    (step) => step.text !== '' && !step.is_substep,
  )

  return (
    <Wrapper isMobile={isMobile}>
      <TopBlock>
        <div style={{ display: 'flex' }}>
          <h1>{!isFetching && steps[displayedStepNumber - 1]?.tutorial?.name}</h1>
        </div>
      </TopBlock>
      {isFetching ? <LoaderMini /> : <Container {...handlers} >
      {!isMobile && (
          <PhoneContainer 
            frameWidth={frameProperties.wrapperWidth}
            onClick={nextSubstep}
          >
            <ImageWrapper frameWidth={frameProperties.wrapperWidth}>
              <PhoneFrame
                onLoad={() => {
                  setFrameProperties({
                    wrapperWidth: phoneFrameRef.current.offsetWidth,
                    homeScreenOffsetTop:
                      -phoneFrameRef.current.offsetHeight
                      + borderPhone?.screenYUpperLeft,
                  })
                }}
                src={borderPhone?.imageLocation}
                ref={phoneFrameRef}
                alt=''
                aria-hidden
              />
              <ScreenPhoneImage
                alt=''
                aria-hidden
                src={imageUrl}
                offsetTop={frameProperties.homeScreenOffsetTop}
                offsetLeft={borderPhone?.screenXUpperLeft}
                XSize={borderPhone?.screenXSize}
                YSize={borderPhone?.screenYSize}
                ZPosition={borderPhone?.faceplate_behind}
              />
            </ImageWrapper>
            {currentStep?.action.type !== 'automate' && (
              <TouchButtonContainer
                ButtonXPos={buttonXPos}
                ButtonYPos={buttonYPos}
                Swap={getAnimationInfo(currentStep?.action?.type)}
                BoxShadow={getBoxShadowAnimation(currentStep?.action?.type)}
              >
                <button />
              </TouchButtonContainer>
            )}
          </PhoneContainer>)}
        <Section id="main-content">
        {isMobile && (
          <SwipeWrapper>
            {displayedStepNumber > 1 && <PrevStep />}
            <NextStep text={getStepButtonText()} />
          </SwipeWrapper>
        )}
        {!isMobile ? (
            <BottomBlock>
              {!isFetching
                && DisplaySteps(
                  filteredSteps,
                  displayedStepNumber,
                  changeActiveStep,
                  inputRef,
                  isMobile,
                )}
            </BottomBlock>
        ) : (
            <OneStep>
              {!isFetching
                && DisplayStep(filteredSteps, displayedStepNumber, inputRef, isMobile)}
            </OneStep>
        )}
         {isMobile && (
            <SliderDotWrapper tabIndex='0' aria-label={`carousel containing ${filteredSteps.length} slides`}>
              {filteredSteps.map((_, index) => (
                <SliderDot
                  tabIndex='0'
                  aria-label={`${index + 1} step ${(displayedStepNumber - 1 === index) ? 'pressed' : 'unpressed'}`}
                  key={index}
                  onClick={() => {
                    changeActiveStep(index + 1)
                  }}
                  onKeyDown={pressHotkeys}
                  isCurrentStep={displayedStepNumber - 1 === index}
                />
              ))}
            </SliderDotWrapper>
         )}
          {isMobile && (
          <PhoneContainer
            frameWidth={frameProperties.wrapperWidth}
            onClick={nextSubstep}
          >
            <ImageWrapper frameWidth={frameProperties.wrapperWidth}>
              <PhoneFrame
                onLoad={() => {
                  setFrameProperties({
                    wrapperWidth: phoneFrameRef.current.offsetWidth,
                    homeScreenOffsetTop:
                      -phoneFrameRef.current.offsetHeight
                      + borderPhone?.screenYUpperLeft,
                  })
                }}
                src={borderPhone?.imageLocation}
                ref={phoneFrameRef}
                alt=''
                aria-hidden
              />
              <ScreenPhoneImage
                alt='Current phone'
                loading="lazy"
                src={imageUrl}
                offsetTop={frameProperties.homeScreenOffsetTop}
                offsetLeft={borderPhone?.screenXUpperLeft}
                XSize={borderPhone?.screenXSize}
                YSize={borderPhone?.screenYSize}
                ZPosition={borderPhone?.faceplate_behind}
              />
            </ImageWrapper>
            {currentStep?.action.type !== 'automate' && (
              <TouchButtonContainer
                ButtonXPos={buttonXPos}
                ButtonYPos={buttonYPos}
                Swap={getAnimationInfo(currentStep?.action?.type)}
                BoxShadow={getBoxShadowAnimation(currentStep?.action?.type)}
              >
                <button tabIndex='0' aria-hidden='true' />
              </TouchButtonContainer>
            )}
          </PhoneContainer>)}

        </Section>
      </Container>}

      {!isFetching && !isMobile && (
        <NavButton
          onClick={() => {
            if (displayedStepNumber === filteredSteps.length) { navButtonClick(); return }
            nextSubstep()
          }}
          title={getStepButtonText()}
          disabled={isSubmitting}
        />
      )}
    </Wrapper>
  )
}

export default TutorialSteps
